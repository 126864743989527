<!--
  系统管理-用户管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
	<el-card class="box-card">
	  <div slot="header" class="card-header">
      <h3 class="card-title titleCont" style="padding-left: 0px;">
        设置“{{userRuleForm.name}}/{{userRuleForm.userName}}”的接口
      </h3>
	  </div>
	  <div class="card-body p-0">
	    <el-table @selection-change="handleSelectionChange" stripe :data="item" class="tableTwo table-hover text-nowrap " ref="interfaceTable"
	    					style="width: 100%; margin-top: 0px; ">
			  <el-table-column type="selection" width="60px" :selectable="selectable"></el-table-column>
			  <el-table-column prop="title" label="标题">
				  <template slot-scope="scope">
					  <span>{{scope.row.title}}</span>
				  </template>
			  </el-table-column>
				<el-table-column prop="interfaceName" label="接口">
				  <template slot-scope="scope">
						<span>{{scope.row.interfaceName}}</span>
				  </template>
				</el-table-column>
				<el-table-column prop="isOnline" label="上线">
				 <template slot-scope="scope">
				 	<el-switch
				 	  v-model="scope.row.isOnline"
				 	  disabled>
				 	</el-switch>
				 </template>
				</el-table-column>
				<el-table-column prop="order" label="排序">
				 <template slot-scope="scope">
				 	<span>{{scope.row.order}}</span>
				 </template>
				</el-table-column>
				<el-table-column prop="lastUpdateTime" label="更新时间">
				 <template slot-scope="scope">
				 	<span>{{scope.row.lastUpdateTime | dateformatMinute}}</span>
				 </template>
				</el-table-column>
				<el-table-column prop="createUser" label="创建人">
				 <template slot-scope="scope">
						<span v-if="scope.row.createUser!=null">
							{{scope.row.createUser.name}}/{{scope.row.createUser.userName}}
						</span>
				 </template>
				</el-table-column>
	    </el-table>
	  </div>
	</el-card>
	
	<div class="row" style="margin-top: 10px;">
			<div class="col-12">
				<el-button  icon="el-icon-back" @click="onBack()">返回</el-button>
				<el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
			</div>
	</div>
  </div>
</template>

<script>

  export default{
    components:{

    },
    data(){
      return{
				selectInterfaces:[],
				fullscreenLoading:false,
				page:null,//分页查询接口返回的结果
				item:[], //列表数据
				pageBtnList:null,//分页左侧按钮集合
				paging: { //分页数据
					pageLength: 0, // 总共页数
					Size: 10, // 当前请求数量
					Index: 1 //当前在第几页
				},
				isPower: {

				},
				SearchList: null,//搜索集合
				userRuleForm:{},
      };
    },
	created()
	{
		let that = this;
		let userId = that.$route.params.Id;
		var detailUrl = "/api/admin/sysUser/" + userId;
		that.fullscreenLoading = true;
		ajaxGet(that, detailUrl, null, function (r) {
			if(r != null)
			{
				that.userRuleForm = r;
				that.ruleFormReset = JSON.parse(JSON.stringify(r));
			}
			that.fullscreenLoading = false;
		
		});
		/*isPower(that, "sysuser_details","sysuser_details");
		isPower(that, "sysuser_create","sysuser_create");
		isPower(that, "sysuser_edit","sysuser_edit");
		isPower(that, "sysuser_delete","sysuser_delete");
    isPower(that, "sysuser_range","sysuser_range");
    isPower(that, "sysuser_initPassword","sysuser_initPassword");*/
		pagingPage(that);
	},
	methods:{
		onSave()
		{
			let that = this;
			var ids = [];
			for(var it of that.selectInterfaces)
			{
				ids.push(it.interfaceId);
			}
			that.fullscreenLoading = true;
			var data= {
				UserId:that.$route.params.Id,
				InterfaceIds:ids,
			};
			ajaxPost(that,"/api/admin/sysInterface/SaveUser",data,function(r){
				that.fullscreenLoading = false;
			  that.onBack();
			});
		},
		onBack()
		{
			let that = this;
			back(that);
		},
		handleSelectionChange(val, index) {
				let that = this;
				that.selectInterfaces = val;
		},
		selectable(row, index) {
				let that = this;
				if(row.isCurrentUser == true)
				{
					return false;
				}
				else{
					return true;
				}
		},
		onSearch(params)
		{
			let that = this;
			let data = {};
			let searchData = params;
			searchData.forEach(element => {
				if (element.data) {
					data[element.zhi] = element.data;
				}
			});
			data.PageNumer = 1;
			routerPath(that, "/Admin/Interface/index", data, null, "PageSize");
		},


		pageBtnClick(index) {
			//分页组件左侧的按钮事件，按顺序进行判断
		},
		pagingClick(type, val) {
			let that = this;
			let url = "/Admin/Interface/index";
			if (type == 1) {//更改每页条数触发的事件
				routerPath(
					that,
					url,
					"PageSize",
					val,
					"PageNumer",
					"Name",

				);
			} else { //更改当前页时触发的事件
				routerPath(
					that,
					url,
					"PageNumer",
					val,
					"PageSize",
					"Name",
				);
			}
		},
	},
	watch: {
		$route() {
			let that = this;
			pagingPage(that);
		}
	}
  };

 function pagingPage(that) {
	  paging(
		  that,
		  function (r) {
			  let Myurl = "/api/admin/sysInterface/getItem/" + that.$route.params.Id;
			  let data = {
				  PageNumer: r.PageNumer,
				  PageSize: r.PageSize,
				  Name: r.Name,
			  };

			  that.SearchList = [
				  {
					  type: "input",
					  Name: "标题",
					  data: r.Name,
					  holder: "请输入标题",
					  zhi: "Name"
				  },
			  ];
			  that.fullscreenLoading = true;
			  ajaxGet(that, Myurl, data, function (r) {
					console.log(r);
				  that.item = r;
					that.$nextTick(() => {
							for(var it of that.item)
							{
								if(it.isSelected == true)
								{
									 that.$refs.interfaceTable.toggleRowSelection(it, true);
								}
								else{
									 that.$refs.interfaceTable.toggleRowSelection(it, false);
								}
							}
					});
					
				  that.fullscreenLoading = false;
			  });
		  },
		  "Name",
	  );
  }
</script>

<style scoped="scoped">

</style>
